<template>
  <div v-if="form">
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col sm="6">
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            Terms & Conditions
          </h1>
        </b-col>
        <b-col sm="6" class="text-center text-sm-right mb-3 mb-sm-0">
          <p class="mb-0 d-inline-block mr-3 label-text">
            Version : {{ form.version | numeral("0,0.00") }}
          </p>
          <b-dropdown
            id="dropdown-1"
            text="Version"
            class="version-dd"
            variant="primary"
            right
          >
            <b-dropdown-item
              v-for="(item, index) in dataVersion"
              :key="index"
              @click="showDataByVersionId(item.id)"
              >{{ item.name | numeral("0,0.00") }}</b-dropdown-item
            >
          </b-dropdown>
          <p class="label-text last-update-txt">
            Last Updated : {{ form.createdTime | moment($formatDateTime) }}
          </p>
        </b-col>
      </b-row>

      <div class="bg-white bg-white-border p-3">
        <b-row>
          <b-col>
            <b-row class="mt-3">
              <b-col>
                <TextEditor
                  v-if="form != null"
                  textFloat="Description"
                  :rows="8"
                  :value="form.content"
                  name="termsandconditions"
                  placeholder="Type something..."
                  isRequired
                  :v="$v.form.content"
                  @onKeyUpChange="onChangeTermsAndCondition"
                  @onDataChange="onChangeTermsAndCondition"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col md="6"></b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              @click="checkForm(1)"
              :disabled="isDisable"
              class="btn btn-success btn-details-set ml-md-2 text-uppercase"
            >
              save
            </button>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />

    <b-modal
      ref="setVersionModal"
      id="setVersionModal"
      hide-header
      hide-footer
      centered
    >
      <div class="modal-header">
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('setVersionModal')"
        >
          ×
        </button>
      </div>
      <div class="text-center">
        <p class="text-text my-3">
          You made some changes, Please enter version you would like to save
        </p>

        <b-form-input
          v-model="versionNo"
          placeholder="Version"
          step="any"
          @keyup="onChangeVersionNo($event.target.value)"
        ></b-form-input>

        <div class="mt-4">
          <button
            type="button"
            @click="submit"
            :disabled="isDisableModalBtn"
            class="btn btn-main btn-details-set ml-md-2 text-uppercase"
          >
            save
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="setPreviousVersionDataModal"
      id="setPreviousVersionDataModal"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <div class="modal-header">
        <h4 class="font-weight-bold">
          Preview Data (Last Updated :
          {{ previousData.createdTime | moment($formatDateTime) }})
        </h4>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('setPreviousVersionDataModal')"
        >
          ×
        </button>
      </div>
      <div class="previousData-box">
        <span v-html="previousData.content"></span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TextEditor from "@/components/inputs/TextEditor";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import { required } from "vuelidate/lib/validators";
import Vue from "vue";
export default {
  name: "AboutUs",
  components: {
    TextEditor,
    ModalAlert,
    ModalAlertError,
  },
  data() {
    return {
      isDisable: true,
      isDisableModalBtn: false,
      modalMessage: "",
      previousData: "",
      versionNo: 0,
      oldData: "",
      isSuccess: false,
      id: this.$route.params.id,
      modalAlertShow: false,
      form: null,
      dataVersion: [],
    };
  },
  validations: {
    form: {
      content: { required },
    },
  },
  created: async function () {
    await this.getDatas();
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getDatas: async function () {
      let versions = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/TermsAndConditoins/List`,
        null,
        this.$headers,
        null
      );

      if (versions.result == 1) {
        this.dataVersion = versions.detail;
      }

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/TermsAndConditoins`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail;
        this.oldData = this.form.content;
        var sum = parseFloat(this.form.version) + parseFloat(0.01);
        this.versionNo = sum.toFixed(2);
        this.$v.form.$reset();

        this.$isLoading = true;
      }
    },
    checkForm: async function (flag) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.modalAlertShow = false;
      this.flag = flag;
      //this.submit();

      this.$refs.setVersionModal.show();
    },
    submit: async function () {
      this.isDisable = true;
      this.form.version = this.versionNo;
      this.$refs.setVersionModal.hide();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/TermsAndConditoins/save`,
        null,
        this.$headers,
        this.form
      );

      //this.modalAlertShow = true;
      this.modalMessage = data.message;
      this.isDisable = false;
      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        this.isDisable = true;
        this.getDatas();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    onChangeTermsAndCondition(value) {
      this.form.content = value;
      if (this.oldData == value) {
        this.isDisable = true;
      } else {
        this.isDisable = false;
      }
    },
    onChangeVersionNo(val) {
      var oldVer = parseFloat(this.form.version);
      var newVer = parseFloat(this.versionNo);

      if (newVer <= oldVer) {
        this.isDisableModalBtn = true;
      } else {
        this.isDisableModalBtn = false;
      }
    },
    showDataByVersionId: async function (id) {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/TermsAndConditoins/` + id,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.previousData = data.detail;
        this.$refs.setPreviousVersionDataModal.show();
      }
    },
  },
};
</script>


<style scoped>
::v-deep .version-dd ul {
  width: auto !important;
  padding: 0.5rem 0 !important;
  max-height: 300px;
  overflow-y: auto;
}

.previousData-box {
  max-height: 500px;
  overflow-y: auto;
}

.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  font-family: "Kanit-SemiBold" !important;
  color: #16274a;
  margin-bottom: 0;
  font-size: 24px;
}

.modal-body {
  margin: auto;
  text-align: center;
}

::v-deep .version-dd button {
  background-color: #f47306 !important;
  border-color: #f47306 !important;
}

.last-update-txt {
  font-size: 14px;
  font-weight: normal;
  margin: 5px 0;
}
</style>